import React, { useState } from 'react';
import Layout from '../../components/chat-channel/Layout';
import Container from '../../components/chat-channel/Container';
import TitleAndMetaTags from '../../components/common/TitleAndHeader';
import { RequestForm } from '../../components/form';
import { PlayIcons } from '../../react/Icons';
import { RequestFormModal } from '../../components/modalform';

const BasicVideoTemplate = (props) => {
  const { pageContext } = props;
  const {
    header,
    videoTag,
    similarVideos,
    id,
    metaDescription,
    ldSchema,
  } = pageContext;
  console.log(props, 'propstest');
  return (
    <>
      <TitleAndMetaTags
        title={header}
        description={metaDescription}
        keywords={[
          'IT Helpdesk Chatbot',
          'HR Chatbot',
          'Workativ Integrations',
        ]}
        ldSchema={ldSchema}
      />
      <Container>
        <div className="w-100 float-left videos_wrapper">
          <Layout backgroundColor={'bg_videos'} logoFor="ASSISTANT">
            <div className="ddd">
              <VideoPlayWrapper header={header} videoTag={videoTag} />
              <OverviewWrapper id={id} />
              <SimiliarVideosWrapper similarVideos={similarVideos} />
              <RequestForm isFooterForm={true} />
            </div>
          </Layout>
        </div>
      </Container>
    </>
  );
};
export default BasicVideoTemplate;

const SimiliarVideosWrapper = ({ similarVideos }) => {
  return (
    <section className="similar_videos_wrapper">
      <div className="utube_videos col-12 col-lg-12 ">
        <div className="container">
          <div className="row">
            <h3>Similar videos you might like</h3>
            <div className="utube_rows">
              {similarVideos.map((data) => (
                <div className="utube_col_4">
                  <a href={data.link}>
                    <div className="utube_iframe">
                      <div className="position-relative w-100">
                        <span>
                          <PlayIcons />
                        </span>
                        <div className="pos_rel">
                          <img
                            src={`https://img.youtube.com/vi/${data.ImageURl}/mqdefault.jpg`}
                          />
                        </div>
                      </div>
                      <p className="utube_content">{data.header}</p>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const VideoPlayWrapper = ({ header, videoTag }) => {
  return (
    <div className="back_features_hide cc_teams_wrapper">
      <section className="main-slider feature_inner_page  bg_feature_slider bg_feature_slider_wrapper trial_page_img">
        <div className="feature_bg_img bg_videos" />
        <div className="container">
          <div className="row">
            <div className="back_features">
              <div className="back_features_page">
                <a
                  href="javascript:void();"
                  onClick={() => {
                    window.history.back();
                  }}
                  className="url_manipulation"
                >
                  <span>←</span>Back to Videos
                </a>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="main-slider-left">
                <h1>{header}</h1>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 features_change_img_header pl-0 pr-0">
            <div className="features_change_img">
              <div className="features_change_img-center">
                <iframe
                  className="youtube_video"
                  width="100%"
                  height="100%"
                  src={`https://www.youtube.com/embed/${videoTag}?rel=0&amp;controls=1&amp;showinfo=0&autoplay=1&mute=0`}
                  allow="autoplay; encrypted-media"
                  title="YouTube video player"
                  frameborder="0"
                  allowfullscreen
                  allowtransparency="true"
                  style="background: #FFFFFF;"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const OverviewWrapper = ({ id }) => {
  const ContentComponent = OveriewList[id] ? OveriewList[id] : null;

  console.log(OveriewList, id, ContentComponent, 'Overview');
  return (
    <div className="overview_wrapper">
      <section class="skit_contactus sign_up-form_us demo_page">
        <div class="container signup_form">
          <div class="row beta_change_form">
          { OveriewList[id] ? <ContentComponent /> : null }
            <div class="col-md-12 col-sm-12 col-xs-12 demo_page_right">
              <div className="overview_right_wrpper video_page_upt">
                <ResourcesWrapper />
                <GetStartedWrapper />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const ResourcesWrapper = () => {
  return (
    <div className="resource_wrapper">
      <h6>Resources</h6>
      <ul>
        <li>
          <a href="https://blog.workativ.com/top-9-reasons-to-build-a-digital-workplace-workativ/">
            Sign up with Workativ Assistant
          </a>
        </li>
        <li>
          <a href="https://blog.workativ.com/5-benefits-of-introducing-hr-bots-to-support-remote-workers/">
            5 Benefits of Introducing HR Bots to Support Remote Workers
          </a>
        </li>
      </ul>
    </div>
  );
};

const GetStartedWrapper = () => {
  const [plan, setPlan] = useState('');

  const [showThanksPage, setShowThanksPage] = useState(false);
  return (
    <div className="get_started_wrapper">
      <h5>
        Kickstart Your Employee Support Transformation With Workativâ€™s IT Help
        Desk Chatbot Today!
      </h5>
      <button
        plan={plan}
        onClick={() => setPlan('homepage')}
        className="home_page_sign url_manipulation"
      >
        Get Started
      </button>

      {plan == '' ? null : (
        <RequestFormModal
          setPlan={setPlan}
          plan={plan}
          showThanksPage={showThanksPage}
          setShowThanksPage={setShowThanksPage}
        />
      )}
    </div>
  );
};

// An overview start
const OverviewContent_G5UIG80a9U0 = () => {
  return (
    <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12 ">
      <>
        <h3>An overview</h3>
        <div className="last_child_p">
          <p>
            This video shows how admins can build & deploy an effective
            conversational IT support chatbot in Teams in less than 10 minutes
          </p>
          <p>
            We take you through Workativ Assistant, a SaaS no-code platform that
            builds, automates and deploys conversational AI chatbots with
            workflow automation.
          </p>
          <p>
            In this video, we will cover Chatbot Builder, Workflow Builder and
            Chatbot integration functionalities along with a end-user chatbot IT
            support scenarios.{' '}
          </p>
        </div>
        <ul>
          <li>
            <b>Chatbot Builder</b> - Builds chatbot dialogs for various end-user
            use cases
          </li>
          <li>
            <b>Workflow Builder</b> - Create and deploy your app workflows
            actions in order to execute in the chatbot
          </li>
          <li>
            <b>Chatbot Integration</b> - Deploy the chatbot in your favorite
            chat channels such as MS Teams or Slack
          </li>
        </ul>
      </>
    </div>
  );
};
const OverviewContent_mPtovhOIac = () => {
  return (
    <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12 ">
      <>
        <h3>An overview</h3>
        <div className="last_child_p">
          <p>
            This video presents how admins can build & deploy an effective IT
            helpdesk chatbot in Teams in less than 10 minutes
          </p>
          <p>
            We take you through Workativ Assistant, a SaaS no-code platform that
            builds, automates and deploys conversational AI chatbots with
            workflow automation.
          </p>
          <p>
            In this video, we will cover Chatbot Builder, Workflow Builder and
            Chatbot integration functionalities along with a end-user chatbot IT
            support scenarios.
          </p>
        </div>
        <ul>
          <li>
            <b>Chatbot Builder</b> - Builds chatbot dialogs for various end-user
            use cases
          </li>
          <li>
            <b>Workflow Builder</b> - Create and deploy your app workflows
            actions in order to execute in the chatbot
          </li>
          <li>
            <b>Chatbot Integration</b> - Deploy the chatbot in your favorite
            chat channels such as MS Teams or Slack
          </li>
        </ul>
      </>
    </div>
  );
};
const OverviewContent_cAB_LViSwPg = () => {
  return (
    <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12 ">
      <>
        <h3>An overview</h3>
        <div className="last_child_p">
          <p>
            See how an HR admin builds and deploys a powerful conversational HR
            support chatbot in Teams for employees in less than 10 minutes
          </p>
          <p>
            We take you through Workativ Assistant, a SaaS no-code platform that
            builds, automates and deploys conversational AI chatbots with
            workflow automation.
          </p>
          <p>
            In this video, we would cover Chatbot Builder, Workflow Builder and
            Chatbot integration functionalities along with a end-user chatbot
            for HR support scenarios
          </p>
        </div>
        <ul>
          <li>
            <b>Chatbot Builder</b> - Builds chatbot dialogs for various end-user
            use cases
          </li>
          <li>
            <b>Workflow Builder</b> - Create and deploy your app workflows
            actions in order to execute in your dialogs
          </li>
          <li>
            <b>Chatbot Integration</b> - Deploy your chatbot in your favorite
            chat channels such as MS Teams or Slack
          </li>
        </ul>
      </>
    </div>
  );
};
const OverviewContent_6UrecdcFJTg = () => {
  return (
    <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12 ">
      <>
        <h3>An overview</h3>
        <div className="last_child_p">
          <p>
            See how an HR admin builds and deploys a powerful HR chatbot in
            Teams for employees in less than 10 minutes
          </p>
          <p>
            We take you through Workativ Assistant, a SaaS no-code platform that
            builds, automates and deploys conversational AI chatbots with
            workflow automation.
          </p>
          <p>
            In this video, we would cover Chatbot Builder, Workflow Builder and
            Chatbot integration functionalities along with an end-user chatbot
            for HR support scenarios
          </p>
        </div>
        <ul>
          <li>
            <b>Chatbot Builder</b> - Builds chatbot dialogs for various end-user
            use cases
          </li>
          <li>
            <b>Workflow Builder</b> - Create and deploy your app workflows
            actions in order to execute in your dialogs
          </li>
          <li>
            <b>Chatbot Integration</b> - Deploy your chatbot in your favorite
            chat channels such as MS Teams or Slack
          </li>
        </ul>
      </>
    </div>
  );
};
const OverviewContent_yA8OwpyZv = () => {
  return (
    <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12 ">
      <>
        <h3>An overview</h3>
        <div className="last_child_p">
          <p>
            Learn how to create an intelligent Virtual Agent with workflow
            automation capabilities in less than 10 minutes
          </p>
          <p>
            We take you through Workativ Assistant, a SaaS no-code platform that
            builds, automates and deploys conversational AI chatbots with
            workflow automation.
          </p>
          <p>
            In this video, we would cover Chatbot Builder, Workflow Builder and
            Chatbot integration functionalities along with a end-user chatbot
            for employee support
          </p>
        </div>
        <ul>
          <li>
            <b>Chatbot Builder</b> - Builds chatbot dialogs for various end-user
            use cases
          </li>
          <li>
            <b>Workflow Builder</b> - Create and deploy your app workflows
            actions in order to execute in your dialogs
          </li>
          <li>
            <b>Chatbot Integration</b> - Deploy your chatbot in your favorite
            chat channels such as MS Teams or Slack
          </li>
        </ul>
      </>
    </div>
  );
};
const OverviewContent_DNaVY8rh0PQ = () => {
  return (
    <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12 ">
      <>
        <h3>An overview</h3>
        <div className="last_child_p">
          <p>
            Deploy no code conversational AI chatbot using Workativ Assistant
            Platform within 10 minutes
          </p>
          <p>
            We take you through Workativ Assistant, a SaaS no-code platform that
            builds, automates and deploys conversational AI chatbots with
            workflow automation.
          </p>
          <p>
            In this video, we would cover Chatbot Builder, Workflow Builder and
            Chatbot integration functionalities along with end-user chatbot
            scenarios for employee support
          </p>
        </div>
        <ul>
          <li>
            <b>Chatbot Builder</b> - Builds chatbot dialogs for various end-user
            use cases
          </li>
          <li>
            <b>Workflow Builder</b> - Create and deploy your app workflows
            actions in order to execute in your dialogs
          </li>
          <li>
            <b>Chatbot Integration</b> - Deploy your chatbot in your favourite
            chat channels such as MS Teams or Slack"
          </li>
        </ul>
      </>
    </div>
  );
};
const OverviewContent_urJRpF3G4Ws = () => {
  return (
    <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12 ">
      <>
        <h3>An overview</h3>
        <div className="last_child_p">
          <p>
            Learn how to create an intelligent Digital Assistant with workflow
            automation capabilities in less than 30 minutes
          </p>
          <p>
            We take you through Workativ Assistant, a SaaS no-code platform that
            builds, automates and deploys conversational AI chatbots with
            workflow automation.
          </p>
          <p>
            In this video, we would cover Chatbot Builder, Workflow Builder and
            Chatbot integration functionalities along with a enduser chatbot for
            workplace support
          </p>
        </div>
        <ul>
          <li>
            <b>Chatbot Builder</b> - Builds chatbot dialogs for various end user
            use casesÂ
          </li>
          <li>
            <b>Workflow Builder</b> - Create and deploy your app workflows
            actions in order to execute in your dialogs
          </li>
          <li>
            <b>Chatbot Integration</b> - Deploy your chatbot in your favourite
            chat channels such as MS Teams or Slack
          </li>
        </ul>
      </>
    </div>
  );
};
const OverviewContent_uMk0k2f = () => {
  return (
    <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12 ">
      <>
        <h3>An overview</h3>
        <div className="last_child_p">
          <p>
            Step-by-step guide: How to Automate Add-distribution group in Office
            365 via IT & HR Helpdesk Chatbot
          </p>
        </div>
        <ul>
          <li>Signup with Workativ Assistant</li>
          <li>Connect your Office 365 Account with Workativ Account</li>
          <li>Automate Office 365 tasks without any coding</li>
          <li>
            Deploy the automation on your businessâ€™ chat channel(s) like Slack
            or Microsoftâ€™s Teams and let Workativ Assistant do all the work for
            you!
          </li>
        </ul>
      </>
    </div>
  );
};
const OverviewContent_GemqItg = () => {
  return (
    <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12 ">
      <>
        <h3>An overview</h3>
        <div className="last_child_p">
          <p>
            Step-by-step guide: How to Automate Add-user to group in Office 365
            via IT & HR Helpdesk Chatbot
          </p>
        </div>
        <ul>
          <li>Signup with Workativ Assistant</li>
          <li>Connect your Office 365 Account with Workativ Account</li>
          <li>Automate Office 365 tasks without any coding</li>
          <li>
            Deploy the automation on your businessâ€™ chat channel(s) like Slack
            or Microsoftâ€™s Teams and let Workativ Assistant do all the work for
            you!
          </li>
        </ul>
      </>
    </div>
  );
};
const OverviewContent_rpiAv3uNIek = () => {
  return (
    <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12 ">
      <>
        <h3>An overview</h3>
        <div className="last_child_p">
          <p>
            Step-by-step guide: How to Automate Delete-group in Office 365 via
            IT & HR Helpdesk Chatbot{' '}
          </p>
        </div>
        <ul>
          <li>Signup with Workativ Assistant</li>
          <li>Connect your Office 365 Account with Workativ Account</li>
          <li>Automate Office 365 tasks without any coding</li>
          <li>
            Deploy the automation on your businessâ€™ chat channel(s) like Slack
            or Microsoftâ€™s Teams and let Workativ Assistant do all the work for
            you!
          </li>
        </ul>
      </>
    </div>
  );
};
const OverviewContent_eKNznulyJ = () => {
  return (
    <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12 ">
      <>
        <h3>An overview</h3>
        <div className="last_child_p">
          <p>
            Step-by-step guide: How to Automate Remove-user from group in Office
            365 via IT & HR Helpdesk Chatbot
          </p>
        </div>
        <ul>
          <li>Signup with Workativ Assistant</li>
          <li>Connect your Office 365 Account with Workativ Account</li>
          <li>Automate Office 365 tasks without any coding</li>
          <li>
            Deploy the automation on your businessâ€™ chat channel(s) like Slack
            or Microsoftâ€™s Teams and let Workativ Assistant do all the work for
            you!
          </li>
        </ul>
      </>
    </div>
  );
};
const OverviewContent_KufPi11UEPg = () => {
  return (
    <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12 ">
      <>
        <h3>An overview</h3>
        <h6>
          Step-by-step guide: How to Automate Reset-password in Microsoft Azure
          via Helpdesk Chatbot
        </h6>
        <ul>
          <li>Signup with Workativ Assistant</li>
          <li>Connect your Microsoft Azure Account with Workativ Account</li>
          <li>Automate Microsoft Azure tasks without any coding</li>
          <li>
            Deploy the automation on your businessâ€™ chat channel(s) like Slack
            or Microsoftâ€™s Teams and let Workativ Assistant do all the work for
            you!
          </li>
        </ul>
      </>
    </div>
  );
};
const OverviewContent_ns4HX5ggV78 = () => {
  return (
    <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12 ">
      <>
        <h3>An overview</h3>
        <h6>
          Step-by-step guide: How to Automate Unlock-account in Microsoft Azure
          via IT & HR Helpdesk Chatbot
        </h6>
        <ul>
          <li>Signup with Workativ Assistant</li>
          <li>Connect your Microsoft Azure Account with Workativ Account</li>
          <li>Automate Microsoft Azure tasks without any coding</li>
          <li>
            Deploy the automation on your businessâ€™ chat channel(s) like Slack
            or Microsoftâ€™s Teams and let Workativ Assistant do all the work for
            you!
          </li>
        </ul>
      </>
    </div>
  );
};
const OverviewContent_SboeWwk8ClM = () => {
  return (
    <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12 ">
      <>
        <h3>An overview</h3>
        <h6>
          Step-by-step guide: How to Automate Get-user details in Microsoft
          Azure via IT & HR Helpdesk Chatbot
        </h6>
        <ul>
          <li>Signup with Workativ Assistant</li>
          <li>Connect your Microsoft Azure Account with Workativ Account</li>
          <li>Automate Microsoft Azure tasks without any coding</li>
          <li>
            Deploy the automation on your businessâ€™ chat channel(s) like Slack
            or Microsoftâ€™s Teams and let Workativ Assistant do all the work for
            you!
          </li>
        </ul>
      </>
    </div>
  );
};

const OveriewList = {
  G5UIG80a9U0: OverviewContent_G5UIG80a9U0,
  '-mPtovhOIac': OverviewContent_mPtovhOIac,
  'cAB-LViSwPg': OverviewContent_cAB_LViSwPg,
  '6UrecdcFJTg': OverviewContent_6UrecdcFJTg,
  'yA8OwpyZv-M': OverviewContent_yA8OwpyZv,
  DNaVY8rh0PQ: OverviewContent_DNaVY8rh0PQ,
  urJRpF3G4Ws: OverviewContent_urJRpF3G4Ws,
  'uMk0k2f-ZX0': OverviewContent_uMk0k2f,
  'moD-GemqItg': OverviewContent_GemqItg,
  rpiAv3uNIek: OverviewContent_rpiAv3uNIek,
  'eKNznulyJ-g': OverviewContent_eKNznulyJ,
  KufPi11UEPg: OverviewContent_KufPi11UEPg,
  ns4HX5ggV78: OverviewContent_ns4HX5ggV78,
  SboeWwk8ClM: OverviewContent_SboeWwk8ClM,
};

// An overview stop